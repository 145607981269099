// import { EditModule } from "../EditModule"

export const MasterSet = (props) => {
    return (
        <div>
            <div className="perenos">
            <div><b>{props.camp.namecomp}</b></div>
            <div><hr style={{margin: '7px 0', width: '100%'}}/></div>
            </div>
        </div>
    )
}