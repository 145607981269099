export const fix = {
    listOfFields: [
        {name: 'Манагер', index: 'manager'},
        {name: 'Фамилия, Имя заказчика', index: 'name'},
        {name: 'Адрес', index: 'addres'},
        {name: 'Телефон', index: 'clientTel'},
        {name: 'Устройство', index: 'title'},
        {name: 'Модель', index: 'model'},
        {name: 'Серийный номер', index: 'sn'},
        {name: 'Неисправность', index: 'problem'},
        {name: 'Внешний вид', index: 'view'},
        {name: 'Комплект', index: 'complect'},
        {name: 'Согласованная стоимость', index: 'cost'},
        {name: 'Срочность', index: 'speed'},
        
        {name: 'id', index: 'id'},
        {name: 'Дата', index: 'date'},
        {name: 'Номер заказа', index: 'order'},
        {name: 'История', index: 'history'}  
    ],
    lists: {
       title: ['Ноутбук', 'Системный блок', 'Мобильный телефон', 'Планшет', 'Монитор', 'Телевизор', 'Картридж', 'Колонка'],
       sn: ['Без номера', 'Не найден', 'Не читается'],
       name: [],
       problem: ['Профилактика системы охлаждения', 'Не включается', 'Не заряжается', 'Замена модуля', 'Переклейка стела', 'Переустановка без сохранения инф.', 'Переустановка с сохранением', 'Замена гнезда зу', 'Замена экрана'],
       addres: ['Минск, ул. Сухаревская', 'Минск, ул. Лобанка', 'Минск, ул. Фёдорова', 'Минск, ул. Горецкого'],
       model: ['ASUS', 'ACER', 'HP', 'Lenovo', 'Dell', 'Huawei', 'Redmi', 'Mi', 'Iphone', 'MSI', 'POCO'],
       clientTel: ['+375 29', '+375 33', '+375 44', '+375 25'],
       manager: ['Мистол Кот', 'Казловский Дмитрий', 'Бородач Дмитрий'],
       speed: ['Обычный', 'Срочный'],
       cost: ['0', '75', '100', '130'],
       complect: ['Без комплекта', 'Без батареи и зу', 'Без карты памяти и сим-карт'],
       view: ['Бу', 'Потерт', 'Царапины', 'Сколы', 'Без видимых дефектов']
    },
    searchList: ['Ноутбук', 'Системный блок', 'Мобильный телефон', 'Планшет', 'Монитор', 'Телевизор', 'Картридж', 'Колонка'],
    orderNumbers: {min: 1000, max: 9999},
    mainColor: 'rgb(1, 75, 235)',
    // link: 'http://localhost:5555/api'
    link: 'http://142.93.1.154/api'  
}